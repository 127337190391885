@import '~@travel-ui/styles/src/utility';

.wrapper {
  margin: 0 auto;

  .headerContainer {
    padding: 40px $spacing-m $spacing-m;
    border-bottom: 1px solid $isabelline;
    margin-bottom: $spacing-xl;

    .headerTitle {
      font-size: $font-solar;
      line-height: $line-height-title;
      font-weight: bold;
      margin-bottom: $spacing-base;
    }

    .progressIndicator {
      justify-content: center;
    }
  }

  .contentContainer {
    @include flex-center;
    text-align: center;
    flex-direction: column;

    .contentTitle {
      font-size: $font-venus;
      margin-bottom: $spacing-l;
    }

    .errorMessage {
      margin-bottom: $spacing-l;
      background-color: $ruddy-pink;
      border-radius: $border-radius;
      padding: $spacing-m $spacing-base;
      max-width: 428px;
      text-align: left;

      svg {
        margin-right: $spacing-base;
      }
    }

    .fieldContainer {
      width: 304px;
      margin-bottom: $spacing-m;

      label {
        @include font-label;
        font-size: $font-neptune;
        display: flex;
      }
    }

    .eventBtnContainer {
      @include flex-center;
      width: 100%;
      margin-top: $spacing-m;

      .cancelBtn {
        margin-right: $spacing-m;
      }

      button {
        width: 180px;
      }
    }

    .resetPasswordBtn {
      width: 304px;
      margin-top: $spacing-m;
    }

    .successDialog {
      text-align: center;
    }
  }
}

.textLink {
  cursor: pointer;
  color: $cilantro;
  text-decoration-line: underline;
}

.textBold {
  font-weight: bold;
}
