@import '~@travel-ui/styles/src/utility';

// HELP: Add here you customs styles for this component
.wrapper {
  display: flex;
  background-color: $white;
  border-bottom: 1px solid $isabelline;
  min-height: 100%;
  overflow: hidden;
}

// Error Messages styling
.redText {
  color: $apple-red;
  font-weight: bold;
}

.textLink {
  cursor: pointer;
  color: $cilantro;
  text-decoration-line: underline;
  font-weight: bold;
}
