@import '~@travel-ui/styles/src/utility';

// HELP: Add here you customs styles for this component
.fieldContainer {
  &#{&} {
    width: 304px;
    margin-bottom: $spacing-m;

    label {
      @include font-label;
      font-size: $font-neptune;
      display: flex;
    }
  }
}

.resetPasswordBtn {
  width: 304px;
  margin-top: $spacing-m;
}

.successDialog {
  text-align: center;
}

.toggleBtn {
  @include resetButtonStyle;
}

.eyeOpen path {
  fill: $jet;
}

.required {
  &::after {
    content: '*';
    color: $apple-red;
  }
}
