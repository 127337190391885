@import '~@travel-ui/styles/src/utility';

// HELP: Add here you customs styles for this component
.wrapper {
  background: no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;

  .flexContainer {
    position: relative;
    padding: $spacing-xl;
    padding-right: 120px;
  }

  .container {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 460px;

    .title {
      font-weight: bold;
      font-size: $font-solar;
      line-height: 28px;
      color: $cilantro;
      text-align: center;
      padding: $spacing-xl $spacing-l $spacing-m;
      border-bottom: 1px solid $isabelline;
      margin: 0 $spacing-base;
    }

    .errorMessage {
      margin: $spacing-m $spacing-m 0;
      border-radius: $border-radius;
      padding: $spacing-m $spacing-base;

      svg {
        margin-right: $spacing-base;
      }
    }

    .attentionMessage {
      background-color: $ruddy-pink;
    }

    .infoMessage {
      background-color: $mellow-yellow;
    }

    .form {
      margin: $spacing-m $spacing-xl;

      .fieldContainer {
        margin-bottom: $spacing-m;

        label {
          @include font-label;
          font-size: $font-venus;
          display: flex;
        }
      }

      .passwordContainer {
        display: flex;
        align-items: center;

        .forgotPassword {
          margin-left: $spacing-m;
          font-size: $font-terra;
        }
      }

      .buttonGroup {
        .rememberId {
          margin-bottom: $spacing-xl;
        }

        .loginBtn {
          width: 100%;
          margin-bottom: $spacing-m;
          max-width: 100%;
        }
      }
    }

    .ssoContainer {
      @include flex-center;
      border-top: 1px solid $isabelline;
      padding: $spacing-m $spacing-xl $spacing-xl;

      .label {
        padding-right: $spacing-m * 3;
      }

      .ssoBtn {
        min-width: 120px;
        white-space: nowrap;
      }
    }
  }

  .messageContainer {
    flex-grow: 1;
    position: relative;
  }
  .textContainer {
    color: $white;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    margin-left: 120px;
    opacity: 1;
    position: relative;

    .mainTitle {
      font-size: 46px;
      line-height: 64px;
      margin-bottom: $spacing-s;
    }

    .subTitle {
      font-size: 40px;
      line-height: 56px;
      margin-bottom: $spacing-l;
    }

    h2 {
      line-height: 24px;
    }
  }
}

.textLink {
  cursor: pointer;
  color: $cilantro;
  text-decoration-line: underline;
}

.fontMars {
  font-size: $font-mars;
  line-height: 18px;
}

.imageBackground {
  @include box(100%);
  position: absolute;
  top: 0%;
  right: 0;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: ease-out 0.3s;
  will-change: opacity;

  &.active {
    opacity: 1;
  }

  &.auto {
    transition: ease-out 1s;
  }
}

.imageButtonsWrapper {
  margin-top: $spacing-l;

  .imageButton {
    @include resetButtonStyle;
    @include box(16px);
    background: $white;
    opacity: 0.6;
    border-radius: 50%;
    outline: none;

    &.active {
      opacity: 1;
    }

    &:not(:first-child) {
      margin-left: $spacing-m;
    }
  }
}
