@import '~@travel-ui/styles/src/utility';

// HELP: Add here you customs styles for this component
.title {
  font-weight: bold;
  font-size: $font-solar;
  line-height: 28px;
  color: $cilantro;
  text-align: center;
  padding: $spacing-xl $spacing-l $spacing-m;
  border-bottom: 1px solid $isabelline;
  margin: 0 $spacing-base;
}

.errorMessage {
  margin: $spacing-m $spacing-m 0;
  border-radius: $border-radius;
  padding: $spacing-m $spacing-base;

  svg {
    margin-right: $spacing-base;
  }
}

.attentionMessage {
  background-color: $ruddy-pink;
}

.infoMessage {
  background-color: $mellow-yellow;
}

.form {
  padding: $spacing-m $spacing-xl;

  .fieldContainer {
    margin-bottom: $spacing-m;

    label {
      @include font-label;
      font-size: $font-venus;
      display: flex;
    }
  }

  .passwordContainer {
    display: flex;
    align-items: center;

    .forgotPassword {
      margin-left: $spacing-m;
      font-size: $font-terra;
    }
  }

  .buttonGroup {
    .rememberId {
      margin-bottom: $spacing-m;
    }

    .loginBtn {
      width: 100%;
      margin-bottom: $spacing-20;
      max-width: 100%;
    }
  }
}

.textLink {
  cursor: pointer;
  color: $cilantro;
  text-decoration-line: underline;
}

.announceLink {
  display: block;
  width: 100%;
  cursor: pointer;
  text-decoration-line: underline;
  margin-bottom: $spacing-04;
}

.returnLink {
  cursor: pointer;
  text-decoration-line: underline;

  > span > span {
    color: $blue-raspberry;
  }
}

.toggleBtn {
  @include resetButtonStyle;

  .eyeOpen path {
    fill: $jet;
  }
}

.loginBtnLabel {
  line-height: 35px;
}
