@import '~@travel-ui/styles/src/utility';

$bullet-size: 28px;

.flexCenter {
  align-items: center;
  display: flex;
}

.invisible {
  visibility: hidden;
}

.connector {
  border-bottom: 1px solid $pastel-gray;
  width: calc(50% - #{$bullet-size} / 2);
}

.step {
  align-items: center;
  background: $white;
  border: 1px solid $pastel-gray;
  border-radius: 100%;
  color: $jet;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: $bullet-size;
  line-height: 2;
  text-align: center;
  width: $bullet-size;

  svg {
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }
}

.stepName {
  padding: $spacing-s calc($spacing-l / 2) 0;
  text-align: center;

  @include media(sp) {
    padding: $spacing-s calc($spacing-base / 2) 0;
  }
}

.active {
  background: $cilantro;
  border-color: $cilantro;
  color: $white;
}

.activeStep {
  color: $cilantro;
  font-weight: bold;
}
