@import '~@travel-ui/styles/src/utility';

.iconTextWrapper {
  display: flex;

  &.left {
    flex-direction: row;
  }

  &.right {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  .icon {
    svg {
      min-height: $line-height-body * $font-venus;
    }
  }

  .text {
    align-self: center;
    word-break: break-word;
  }
}
